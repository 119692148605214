import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { darken, makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import {
    Drawer,
    IconButton,
    Typography,
    Box,
    Button,
    Input,
    Paper,
    TableContainer, Table, TableBody, TableRow, TableCell,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
    X as CloseIcon,
} from 'react-feather';
import ShareCart from 'src/layouts/MainLayout/Cart/ShareCart';
import CartItem from 'src/layouts/MainLayout/Cart/CartItem.jsx';
import BundleItem from 'src/layouts/MainLayout/Cart/Bundle/Item.jsx';
import ManagerApi from 'src/services/managerApi';
import { formatPrice, getCartBundlePrice, getTotalProductionTime, getCartItemLimitAlert } from 'src/utils/functions.js';
import { addLoadingGlobal, addMessage, removeLoadingGlobal } from 'src/actions';
// import arrow from 'src/theme/scss/icons/arrow-right.svg';
import arrow from 'src/theme/scss/icons/arrow-right-red.svg';

import { v4 as uuidv4 } from 'uuid';
import DiscountCoupon from 'src/views/Checkout/Cart/DiscountCoupon';
import PaymentOptions from 'src/views/Checkout/Cart/PaymentOptions';
import clsx from 'clsx';
import { trackShippingEstimate, trackViewCart } from 'src/utils/analytics';
const drawerWidth = 640;

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        maxWidth: "95%",
        backgroundColor: '#f4f4f4'
    },
    drawerContainer: {
    },
    drawerHeader: {
        padding: '25px 25px 0 25px'
    },
    drawerContent: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
    topCart: {
        position: 'relative',
    },
    closeButton: {
        position: 'absolute',
        right: 20
    },
    cepCalculate: {
        display: 'flex',
        alignItems: 'center',
        gap: '16px',
    },
    inputControl: {
        margin: '0 10px',
    },
    buttonForm: {
        fontSize: '11px',
        fontWeight: 800,
        color: '#ff367d', // '#db1842',
        padding: '15px 20px',
        backgroundColor: 'rgba(255, 54, 125, 0.1)', // 'rgba(219, 24, 66, 0.1)',
        borderRadius: '6px',
        maxWidth: 140,
        width: '100%',
        '&:hover': {
            backgroundColor: 'rgba(219, 24, 66, 0.2)',
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 90
        }
    },
    productMedia: {
        width: '80px;',
        height: '80px;',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    },
    deleteItemCart: {
        padding: '4px',
        margin: '4px'
    },
    cartItems: {
        borderRadius: 5,
        overflow: 'hidden'
    },
    cartItem: {
        borderRadius: 0,
        boxShadow: 'none',
        padding: '0 15px',
        position: 'relative',
        '&:after': {
            content: '""',
            display: 'block',
            backgroundColor: '#E4E4E4',
            height: 1,
            position: 'absolute',
            right: 15,
            bottom: 0,
            left: 15
        },
        '&:last-child': {
            '&:after': {
                display: 'none',
            },
        }
    },
    btnCheckout: {
        width: '100% !important',
        color: '#ffffff !important',
        backgroundColor: '#0085f8',
        '&:hover': {
            backgroundColor: darken('#0085f8', 0.1),
        },
        '&.Mui-disabled': {
            color: '#777777 !important',
            borderColor: '#777777 !important',
            '& img': {
                display: 'none'
            }
        }
    },
    arrowIcon: {
        marginLeft: 20
    },
    subtotalBox: {
        boxShadow: 'none',
        borderRadius: 5,
        padding: '7px 2px'
    },
    subtotalTitle: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px'
        }
    },
    tableCell: {
        padding: '6px 16px',
        border: 0
    },
    tableCellShort: {
        padding: '2px 16px'
    },
    discountCoupon: {
        width: '100%',
        backgroundColor: '#ffffff',
        padding: '8px 16px',
        borderRadius: 5,
    },
    totalBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    totalText: {
        fontSize: 26,
        color: '#ff367d', // '#db1842',
    },
    shippingSelect: {
        color: '#777777 !important',
        border: '1px solid #E4E4E4',
        borderRadius: '6px !important',
        maxWidth: 300
    },
    shippingBox: {
        backgroundColor: '#FFFFFF',
        padding: '1.5rem 1.25rem',
        borderRadius: '5px'
    },
    shippingOption: {
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '1rem',
        paddingBottom: '1rem',
        '& + &': {
            borderTop: '1px solid #E4E4E4'
        },
        '&:last-of-type': {
            paddingBottom: '0'
        },
        '& span': {
            fontSize: '14px',
        },
    },
    shippingOptionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    shippingPrice: {
        fontSize: '1rem',
        fontWeight: '700',
        color: '#333333',
        '& span': {
            fontSize: '0.75rem',
            fontWeight: '400',
        },
        textAlign: 'right'
    },
    shippingTime: {
        color: '#0084F4',
        fontSize: '0.75rem',
        textAlign: 'left'
    },
    shippingText: {
        fontWeight: '800',
        color: '#333333',
    },
    noCepMessage: {
        color: '#333333',
        marginTop: '.5rem'
    },
    coupomForm: {
        position: 'relative',
        display: 'flex',
    },
    formControl: {
        maxWidth: '200px',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            maxWidth: 'initial',
        },
    },
    coupomBtn: {
        minWidth: 50
    },
    cupomApplied: {
        color: '#db1842',
        fontWeight: 400,
    },
    shippingInfo: {
        color: '#0084F4',
        fontSize: 12,
        textDecoration: 'none',
        '&:hover': {
            color: '#0084F4',
            textDecoration: 'underline',
        }
    },
    shipping: {
        padding: '16px',
        borderRadius: '4px',
        backgroundColor: '#FFFFFF',
    },
    shippingInput: {
        width: '100%',
    },
    sectionTitle: {
        fontSize: '16px',
        fontWeight: '800',
        color: '#333333',
        marginBottom: '8px',
        fontFamily: 'Gilroy-ExtraBold',
    },
}));

export default function DrawerCart({ handleDrawerClose, openDrawer, order }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const customer = useSelector((state) => state.account.user);
    const [translate, i18n] = useTranslation();
    const [openModal, setOpenModal] = useState(false);
    const [shippingRadioValue, setShippingRadioValue] = useState(null);

    const [zipcode, setZipcode] = useState('');
    const [shipping, setShipping] = React.useState("");
    const [shippingQuotes, setShippingQuotes] = useState({
        success: true,
        message: '',
        quotes: [],
    });

    useEffect(() => {
        const firstShippingOption = shippingQuotes.quotes[0];
        if (firstShippingOption) {
            setShipping(firstShippingOption)
            setShippingRadioValue(firstShippingOption.method)
        }
    }, [shippingQuotes])

    function getDiscountValues() {
        const discounts = {};

        if (!order.id) return

        order.offers && order.offers.forEach(offer => {
            if (!offer.paymentMethod) {
                discounts[offer.name] = offer.totalDiscount;
            }
        })

        return discounts;
    }

    const orderDiscounts = getDiscountValues();

    const handleModalOpen = () => {
        !openModal ? setOpenModal(true) : setOpenModal(false);
    };

    const handleModalClose = () => {
        setOpenModal(false);
    };

    const handleZipChange = (e) => {
        const { value } = e.target;
        setZipcode(value);
    }

    const handleShipping = (shipping) => {
        setShipping(shipping);
    }

    const fetchShippingQuote = () => {
        if (!zipcode || zipcode.length < 9) {
            return;
        }
        setShipping(null);

        const shippingQuoteApi = new ManagerApi('/shipping/quote');

        let params = {
            zipcode: zipcode,
            products: []
        }

        order.items.map((item, i) => {
            if (!item.parent) {
                let price = item.price;

                if (item.type == 'bundle')
                    price = getCartBundlePrice(item, order.items);

                params.products.push({
                    product: item.productId,
                    price: price,
                    quantity: item.quantity
                })
            }
        });

        setShippingQuotes({
            success: true,
            quotes: []
        });

        const requestId = uuidv4();

        dispatch(addLoadingGlobal(requestId));

        shippingQuoteApi.getList(params).then((response) => {
            let quotes = response.data.content

            if (quotes.length === 0) {
                dispatch(addMessage('Erro ao buscar CEP', 'error'));
                return;
            }

            setShippingQuotes({
                success: response.data.success,
                message: response.data.message,
                quotes: quotes
            });

        })
            .catch(() => {
                dispatch(addMessage('Erro ao buscar CEP', 'error'));
            })
            .finally(() => {
                dispatch(removeLoadingGlobal(requestId));
            });
    }

    const errorsItemLimite = getCartItemLimitAlert(order.items);

    const showErrorMessages = () => {
        let errors = [];

        if (order.errors) {
            for (let error in order.errors) {
                errors.push(
                    <Box mt={3}>
                        {error === 'productStock' ?
                            <Alert severity="warning">
                                {order.errors.productStock.map(item => item.message)}
                            </Alert>
                            :
                            <Alert severity="warning">
                                {order.errors[error]}
                            </Alert>
                        }
                    </Box>
                )
            }
        }

        if (errorsItemLimite) {

            for (let key in errorsItemLimite) {

                errors.push(
                    <Box mt={3} className={classes.alerts}>
                        <Alert severity="warning">
                            {errorsItemLimite[key].message}
                            Entre em contato com a equipe de vendas para finalizar a compra <RouterLink to="/central-de-vendas" target="_blank">(clicando aqui)</RouterLink>
                        </Alert>
                    </Box>
                )

            }

        }

        return errors;
    }

    const hasCart = (!!order && order.items.length > 0);
    let quantity = 0

    if (order.items.length > 1) {
        quantity = order.items.map(item => parseInt(item.quantity));
    } else if (order.items.length === 1) {
        quantity = order.items.map(item => item.quantity);
    }

    let quantityOrderCart = quantity.length > 0 && quantity.reduce((acc, current) => acc + current);

    useEffect(() => {
        trackViewCart({
            order,
            customer: customer
        })
    }, [openDrawer]);

    return (
        <Drawer
            anchor="right"
            open={openDrawer}
            onClose={handleDrawerClose}
            classes={{
                paper: classes.drawer,
            }}
        >
            <Box className={classes.drawerContainer}>
                <Box className={classes.drawerHeader} width="100%" display="flex" alignItems="center">
                    <Box flexGrow={1} display="flex" alignItems="center">
                        <Box>
                            <Typography variant="h3">{translate('typographyMyCart')}</Typography>
                        </Box>
                        <Box className={classes.closeButton}>
                            <IconButton onClick={handleDrawerClose}>
                                {openDrawer ? <CloseIcon /> : <CloseIcon />}
                            </IconButton>
                        </Box>
                    </Box>
                    {/* <Box ml={2}>
                        {hasCart && (
                            <IconButton onClick={handleModalOpen}>
                                <ShareIcon />
                            </IconButton>
                        )}
                    </Box> */}
                </Box>
                {/* <Divider /> */}
                <Box className={classes.drawerContent} pb={5}>
                    {hasCart ? (
                        <>
                            {Object.keys(order).length > 0 && (
                                <Box mt={3} className={classes.cartItems}>
                                    {order.items.map((item, key) => {
                                        if (item.parent) {
                                            return;
                                        }

                                        return (
                                            <Box component={Paper} key={key} className={classes.cartItem}>
                                                <CartItem item={item} items={order.items} />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            )}

                            <Box mt={3}>
                                <TableContainer component={Paper} className={classes.subtotalBox}>
                                    <Table>
                                        <TableBody>
                                            <TableRow>
                                                <TableCell component="th" scope="row" className={classes.tableCell}>
                                                    <Typography variant="h5">
                                                        {translate('titleSubtotalTableCheckout')}
                                                    </Typography>
                                                </TableCell>
                                                <TableCell align="right" className={classes.tableCell}>
                                                    <Typography
                                                        variant="h4"
                                                        style={{
                                                            color: '#ff367d',
                                                            fontSize: '18px',
                                                        }}
                                                    >
                                                        {formatPrice(order.totalPrice)}
                                                    </Typography>
                                                </TableCell>
                                            </TableRow>
                                            {Object.entries(orderDiscounts).length ? (
                                                <>
                                                    <TableRow>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            className={classes.tableCell}
                                                        >
                                                            <Typography variant="h5">Descontos</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                    {Object.entries(orderDiscounts).map(([discountType, value]) => (
                                                        <TableRow>
                                                            <TableCell
                                                                component="th"
                                                                scope="row"
                                                                className={clsx(
                                                                    classes.tableCell,
                                                                    classes.tableCellShort,
                                                                )}
                                                            >
                                                                <Typography variant="body1">{discountType}</Typography>
                                                            </TableCell>
                                                            <TableCell
                                                                align="right"
                                                                className={clsx(
                                                                    classes.tableCell,
                                                                    classes.tableCellShort,
                                                                )}
                                                            >
                                                                <Typography variant="body1">
                                                                    {formatPrice(value)}
                                                                </Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </>
                                            ) : null}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>         

                            {order.hasShipping ? (
                                <Box mt={3}>
                                    <Box className={classes.shipping}>
                                        <Typography className={classes.sectionTitle}>Opções de frete</Typography>
                                        <form
                                            onSubmit={e => {
                                                e.preventDefault();
                                                trackShippingEstimate();
                                                fetchShippingQuote();
                                            }}
                                            className={classes.cepCalculate}
                                        >
                                            <FormControl className={classes.formControl}>
                                                <InputMask
                                                    mask="99999-999"
                                                    maskChar={null}
                                                    onChange={handleZipChange}
                                                    value={zipcode}
                                                >
                                                    {() => (
                                                        <Input
                                                            placeholder="Digite seu CEP aqui"
                                                            className={classes.shippingInput}
                                                        />
                                                    )}
                                                </InputMask>
                                            </FormControl>
                                            <Button type="submit" className={classes.buttonForm}>
                                                Calcular
                                            </Button>
                                        </form>
                                        {shippingQuotes.quotes.length > 0 && (
                                            <Box mt={1}>
                                                {shippingQuotes.quotes.map(shippingQuote => {
                                                    const formattedPrice = formatPrice(shippingQuote.total);

                                                    const [_, priceSign, priceValue] = /^(\D*)(.*)/.exec(
                                                        formattedPrice,
                                                    );

                                                    const totalProductionTime = getTotalProductionTime(order);

                                                    let shippingQuoteDescription = shippingQuote.description;
                                                    if (shippingQuote.total == 0) {
                                                        shippingQuoteDescription = 'Frete Grátis';
                                                    }

                                                    if (shippingQuoteDescription === 'Braspress Aereo') {
                                                        shippingQuoteDescription = 'Braspress Aéreo';
                                                    }

                                                    return (
                                                        <Box className={classes.shippingOption}>
                                                            <Box className={classes.shippingOptionHeader}>
                                                                <Typography variant="caption">
                                                                    {shippingQuoteDescription}
                                                                </Typography>
                                                                <Typography className={classes.shippingPrice}>
                                                                    <span>{priceSign}</span>
                                                                    {priceValue}
                                                                </Typography>
                                                            </Box>
                                                            <Box>
                                                                <Typography className={classes.shippingTime}>
                                                                    {totalProductionTime > 0 ? (
                                                                        `Prazo de ${totalProductionTime} dias de produção + ${
                                                                            shippingQuote.deliveryTime
                                                                        } ${
                                                                            shippingQuote.deliveryTime === 1
                                                                                ? 'dia útil'
                                                                                : 'dias úteis'
                                                                        } para entrega`
                                                                    ) : (
                                                                        <>
                                                                            {`Prazo de entrega: de ${
                                                                                shippingQuote.deliveryTime
                                                                            } ${
                                                                                shippingQuote.deliveryTime === 1
                                                                                    ? 'dia útil'
                                                                                    : 'dias úteis'
                                                                            } para entrega + prazo de produção `}
                                                                            <RouterLink
                                                                                to="/central-de-vendas"
                                                                                target="_blank"
                                                                                className={classes.shippingInfo}
                                                                            >
                                                                                (consulte aqui)
                                                                            </RouterLink>
                                                                        </>
                                                                    )}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    );
                                                })}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            ) : null}   

                            <Box mt={3} className={classes.discountCoupon}>
                                <DiscountCoupon order={order} showDiscountValue />
                            </Box>

                            <Box mt={3}>
                                <PaymentOptions order={order} shipping={shipping} />
                            </Box>

                            {showErrorMessages()}

                            <Box mt={3}>
                                <Button
                                    fullWidth
                                    component={RouterLink}
                                    variant="contained"
                                    to={{ pathname: `/onestepcheckout` }}
                                    disabled={
                                        order &&
                                        (Object.keys(order.errors).length || Object.keys(errorsItemLimite).length)
                                    }
                                    className={classes.btnCheckout + ' btn'}
                                >
                                    {translate('buttonCheckout')}
                                </Button>
                                <ShareCart openModal={openModal} handleModalClose={handleModalClose} />
                            </Box>
                        </>
                    ) : (
                        <Box mt={3}>
                            <Alert severity="warning">{translate('typographyAlertItemsCart')}</Alert>
                        </Box>
                    )}
                </Box>
            </Box>
        </Drawer>
    );
}
